
<section class="vh-100">
    <div class="container-fluid overflow-hidden d-flex justify-content-center p-0">
        <div class="row w-100">
            <div class="col-xl-7 col-lg-6 col-md-7 px-0 d-xl-block d-lg-block d-none relative">
                <img src="../../../../assets/images/Group 1836.png" alt="Login image" class="w-100 vh-100">
                <div class="img-wraper w-100 vh-100"></div>
                <div class="login-content">
                    <h3>One Stop E-commerce Fulfillment Solution</h3>
                    <p>We guarantee your products from your supplier to your customer in record time with a 100% accuracy.</p>
                </div>
            </div>

            <div class="col-xl-5 col-lg-6 col-md-5 text-black z-index-9">
                <div class="row mt-5 resp-991-logo">
                    <div class="col-lg-12 text-center">
                        <img src="../../../../assets/images/wize-logo.png" alt="Login image" class="logo w-200">
                    </div>
                </div>
                
                <div class="d-flex justify-content-center align-items-center h-custom-2 px-5 ms-xl-5 mt-1 mt-md-1 pt-5 pt-md-0 pt-xl-0 login-wrapper mx-lg-5">
                    <form [formGroup]="loginForm" class="w-100 px-4 login-form">
                        <h3 class="fw-bold mb-3 pb-2 f-s-36">Login</h3>
                        <h6 class="fw-normal mb-1 pb-1 welcome">Welcome back! Please login to your</h6>
                        <h6 class="fw-normal mb-4 pb-4 welcome">account.</h6>
                        
                        <div class="form-outline mb-4 example-container">
                            <label class="form-label d-block">E-mail*</label>
                            <mat-form-field appearance="outline" class="w-100" [ngClass]="{'input-error': loginForm.controls['email'].invalid && submitted}">
                                <!-- <mat-label>Enter e-mail</mat-label> -->
                                <input formControlName="email" matInput name="email">
                            </mat-form-field>
                        </div>

                        <div class="form-outline mb-4 example-container">
                            <label class="form-label d-block">Password*</label>
                            <mat-form-field appearance="outline" class="w-100" [ngClass]="{'input-error': loginForm.controls['password'].invalid && submitted}">
                                <!-- <mat-label>Enter Password</mat-label> -->
                                <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" (keyup.enter)="getLogin($event)" name="password">
                                <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="pt-1 mb-2 d-flex justify-content-between">
                            <c-form-check>
                                <input name="checkOne" cFormCheckInput id="checkOne" required type="checkbox" />
                                <label for="checkOne" cFormCheckLabel>Remember me</label>
                            </c-form-check>
                            <!-- <p class="small mb-2 pb-lg-2"><a (click)="resetpassword()" class="text-primary forgot cursor-pointer">Forgot password?</a></p> -->
                        </div>

                        <div class="pt-1 mb-4">
                            <button *ngIf="!isloggedin == true" [el-loading]="isLoading"
                            class="btn btn-info btn-lg btn-block w-100 custom-btn" 
                            (click)="getLogin($event)" type="button">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<app-custom-snackbar></app-custom-snackbar>
<app-alert></app-alert>
