<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0 w-100">
  <div
    class="row d-flex align-items-start justify-content-between"
    *ngIf="isEditView == true || isCreateView == true"
  >
    <div
      *ngIf="
        moduleName != 'ASN' && moduleName != 'ASN-N' && moduleName != 'assembly'
      "
    >
      <h2
        *ngIf="
          moduleName != 'Stock' &&
          moduleName != 'cycleCount' &&
          moduleName != 'cycleCountLoc' 
        "
        class="title"
      >
        Add {{moduleName === "kitOrders" ?"Kit":"SKUs"}}
      </h2>

      <div
        class="d-flex flex-row-reverse align-items-end justify-content-between pb-3"
      >
        <button mat-flat-button class="btn-main" (click)="polines()">
          <mat-icon class="add">add</mat-icon>
          {{
            moduleName == "Stock"
              ? "Add SKU"
              : moduleName == "cycleCount"
              ? "Select SKUs"  : moduleName == "kitOrders" ?"Add kit"
              : moduleName == "cycleCountLoc"
              ? "Add Location"
              : "Add SKU"
          }}
        </button>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      *ngIf="moduleName == 'assembly'"
    >
      <h2 *ngIf="moduleName == 'assembly'" class="title">Add Components</h2>
      <div
        class="d-flex flex-row-reverse align-items-end justify-content-between pb-3"
      >
        <button mat-flat-button class="btn-main" (click)="polines()">
          <mat-icon class="add">add</mat-icon>Component
        </button>
      </div>
    </div>

    <!-- <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 text-right  mb-3 input-height  d-grid" *ngIf="moduleName != 'ASN'">
      <h2 class="title">Products To Order</h2>
      <mat-form-field class="example-full-width" appearance="outline">
        <input
          matInput
          [(ngModel)]="search_products_array_model"
          name="search_products_array_model"
          placeholder="Type Here"
          [matAutocomplete]="autoLookup"
          (keyup)="debounceProduct()"
          (ngModelChange)="getproductsToOrderList($event)"
        />
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete
          #autoLookup="matAutocomplete"
          class="custom-option"
          [displayWith]="itemDisplayFn"
        >
          <mat-option
            *ngFor="let pro of search_products_array"
            [value]="pro"
            class="custom-option"
            [ngClass]="{ 'hide-autocomplete': !showAutocompletepro }"
          >
            <div class="d-flex align-items-center w-100">
              <img
                aria-hidden
                [src]="
                  pro.attachment
                    ? pro.attachment
                    : '../../../../assets/images/default-image.png'
                "
                alt=""
                class="pro_img-1"
              />

              <div class="d-block pl-5">
                <p cTextColor="secondary" class="mb-1 f-s-12">{{ pro.sku }}</p>
                <p class="t-shirt mb-0 f-s-12" matTooltipHideDelay="1000">
                  {{ pro.name }}
                </p>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div> -->
  </div>
  <div class="line-items-scroll resp-lineItems">
    <table mat-table [dataSource]="products_array" class="mb-3 w-100">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            id="master-chk"
            (change)="$event ? masterToggle($event) : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="
              $event ? selection.toggle(row) : null;
              moduleName == 'SO' || moduleName == 'PR' || moduleName == 'PO'
                ? checkselectedlines()
                : null
            "
            [checked]="selection.isSelected(row)"
            [disabled]="
              (status == 'rfq' && moduleName == 'PR' && row?.po_no !== null) ||
              ((status == 'assigned' || status == 'in-fulfillment') &&
                moduleName == 'SO' &&
                row?.is_in_fulfillment)
            "
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="line_no">
        <th mat-header-cell *matHeaderCellDef> {{moduleName ==='SO' ?'Line#' : 'L.No'}}</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
      </ng-container>

      <!-- Scan Logs -->
      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef>User Name</th>
        <td mat-cell *matCellDef="let element">{{ element.user_name ? element.user_name : "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="date_time">
        <th mat-header-cell *matHeaderCellDef>Date & Time</th>
        <td mat-cell *matCellDef="let element">{{ element.created_at ? formatDate(element.created_at) : "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element" class="text-capitalize">{{ element.action ? element.action : "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Value</th>
        <td mat-cell *matCellDef="let element">{{ element.field ? element.field : "" }} : {{ element.value ? element.value : "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td 
          mat-cell 
          *matCellDef="let element"
          class="text-capitalize"
          [ngStyle]="{'color': element.status === 'Valid' ? '#60B643' : element.status === 'Invalid' ? '#EC2326' : ''}"
        >
          {{ element.status ? element.status : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef>Reason</th>
        <td mat-cell *matCellDef="let element"
        [ngStyle]="{'color': element.status === 'Invalid' && '#EC2326'}"
        >
          {{ element.reason ? element.reason : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="component_name">
        <th mat-header-cell *matHeaderCellDef>Component Name</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex w-170-px">
            <div class="d-flex align-items-center">
              <img
                [src]="
                  element.attachment
                    ? element.attachment
                    : '../../../../assets/images/default-image.png'
                "
                width="33"
                height="33"
                alt=""
                class="pro_img"
              />
              <div
                matTooltipPosition="below"
                [matTooltip]="
                  'SKU: ' + element.product_sku + '\n' + element.product_name
                "
                [matTooltipShowDelay]="500"
                class="d-block pl-5"
                style="max-width: 100px"
              >
                <p
                  cTextColor="secondary"
                  class="sku mb-1 cursor-pointer"
                  matTooltipHideDelay="1000"
                >
                  {{ element.product_sku }}
                </p>
                <p
                  class="text-rap mb-0 cursor-pointer"
                  matTooltipHideDelay="1000"
                >
                  {{ element.product_name }}
                </p>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="assembly_name">
        <th mat-header-cell *matHeaderCellDef>Kit Name</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex w-170-px">
            <div class="d-flex align-items-center">
              <img
                [src]="
                  element.attachment
                    ? element.attachment
                    : '../../../../assets/images/default-image.png'
                "
                width="33"
                height="33"
                alt=""
                class="pro_img"
              />
              <div
                matTooltipPosition="below"
                [matTooltip]="
                  'SKU: ' + element.product_sku + '\n' + element.product_name
                "
                [matTooltipShowDelay]="500"
                class="d-block pl-5"
                style="max-width: 100px"
              >
                <p
                  cTextColor="secondary"
                  class="sku mb-1 cursor-pointer"
                  matTooltipHideDelay="1000"
                >
                  {{ element.product_sku ? element.product_sku : "-" }}
                </p>
                <p
                  class="text-rap mb-0 cursor-pointer"
                  matTooltipHideDelay="1000"
                >
                  {{ element.product_name ? element.product_name : "-" }}
                </p>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="no_of_components">
        <th mat-header-cell *matHeaderCellDef>No. of Components</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex w-170-px">
            <div class="d-flex align-items-center">
              {{ element.no_of_components ? element.no_of_components : "-" }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="no_of_locations">
        <th mat-header-cell *matHeaderCellDef>No. of locations</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex w-170-px">
            <div class="d-flex align-items-center">
              {{ element.no_of_locations ? element.no_of_locations : "-" }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="UPC">
        <th mat-header-cell *matHeaderCellDef>UPC</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex w-170-px">
            <div class="d-flex align-items-center">
              {{ element.upc ? element.upc : "-" }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef>Brand</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['brand']">{{
            element.brand ? element.brand : "-"
          }}</span>
          <span *ngIf="!isDetailView && !disabledColumns['brand']">{{
            element.brand ? element.brand : "-"
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['category']">{{
            element.category ? element.category : "-"
          }}</span>
          <span *ngIf="!isDetailView && !disabledColumns['category']">{{
            element.category ? element.category : "-"
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="po_no">
        <th mat-header-cell *matHeaderCellDef>P.O #</th>
        <td mat-cell *matCellDef="let element" (click)="element.purchase_order_id && navigateToPo(element.purchase_order_id)" [ngClass]="{'po-hyperlink': element.purchase_order_id}">
          {{
            element.po_no
              ? element.po_no
              : element.purchase_order_id
              ? element.purchase_order_id
              : "-"
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="fulfillment_no">
        <th mat-header-cell *matHeaderCellDef>Fulfillment #</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fulfillment_no ? element.fulfillment_no : "-" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ord_no">
        <th mat-header-cell *matHeaderCellDef>Order #</th>
        <td mat-cell *matCellDef="let element">
          {{
            element.ord_no
              ? element.ord_no
              : element.purchase_order_id
              ? element.purchase_order_id
              : "-"
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Product">
        <th mat-header-cell *matHeaderCellDef>SKU <sup *ngIf="moduleName ==='SO'" class="mand-staric">*</sup> </th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex w-170-px">
            <div class="d-flex align-items-center">
              <img
                [src]="
                  element.attachment
                    ? element.attachment
                    : '../../../../assets/images/default-image.png'
                "
                width="33"
                height="33"
                alt=""
                class="pro_img"
              />
              <div
                matTooltipPosition="below"
                [matTooltip]="
                  'SKU: ' + element.product_sku + '\n' + element.product_name
                "
                [matTooltipShowDelay]="500"
                class="d-block pl-5"
                style="max-width: 100px"
              >
                <p
                  cTextColor="secondary"
                  class="sku mb-1 cursor-pointer"
                  matTooltipHideDelay="1000"
                >
                  {{ element.product_sku }}
                </p>
                <p
                  class="text-rap mb-0 cursor-pointer"
                  matTooltipHideDelay="1000"
                >
                  {{ element.product_name }}
                </p>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity_to_transfer">
        <th mat-header-cell *matHeaderCellDef>
          {{
            moduleName == "cycleCount"
              ? "Qty on hand"
              : moduleName == "Stock" && isDetailView
              ? "Available Qty"
              : "Quantity To Transfer"
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.on_hand_qty ? element.on_hand_qty : "-" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="on_hand_qty">
        <th mat-header-cell *matHeaderCellDef>Available Qty</th>
        <td mat-cell *matCellDef="let element">
          {{
            moduleName === "Stock" && isEditView && element.on_hand_qty
              ? element.on_hand_qty
              : element.availbleQty
              ? element.availbleQty
              : "-"
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef>From Location</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span *ngIf="isDetailView">
            {{ element?.from_location ? element?.from_location?.name : "-" }}
          </span>
          <!-- <span *ngIf="!isDetailView">
            {{ element.from ? element.from : "-" }}
          </span> -->
          <div
            class="custom-select poLinesSelectBox"
            style="margin-bottom: 1px"
            *ngIf="!isDetailView"
          >
            <ng-select
              [(ngModel)]="element.from"
              [appendTo]="'body'"
              (open)="handleScroll()"
              (close)="handleScroll()"
              [clearable]="false"
              [searchable]="true"
              placeholder="Select"
              class="line-select"
              (change)="onSelectionChange($event, i)"
              [ngClass]="{
                'input-error': !inValidData && element.from == element.from_id
              }"
            >
              <ng-option

                *ngFor="let item of fromLocationList[element.product_id]"

                [value]="item.location_id"
                >{{ item.location_name }}</ng-option
              >
            </ng-select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="to">
        <th mat-header-cell *matHeaderCellDef>To Location</th>
        <td mat-cell *matCellDef="let element">
          <span class="to-w-10" *ngIf="isDetailView">
            {{ element.to_location ? element.to_location?.name : "-" }}
          </span>
          <div
            class="custom-select poLinesSelectBox"
            style="margin-bottom: 1px"
            *ngIf="!isDetailView"
          >
            <ng-select
              [(ngModel)]="element.to"
              [appendTo]="'body'"
              (open)="handleScroll()"
              (close)="handleScroll()"
              [clearable]="false"
              [searchable]="true"
              placeholder="Select"
              class="line-select"
              [ngClass]="{
                'input-error': !inValidData && element.to == element.from_id
              }"
            >
              <ng-option *ngFor="let item of toLocationList" [value]="item.id">{{
                item?.name
              }}</ng-option>
            </ng-select>
          </div>
          <!-- <div
            *ngIf="!isDetailView"
            class="custom-select poLinesSelectBox"
            style="margin-bottom: 1px"
          >
            <ng-select
              [(ngModel)]="element.to"
              [appendTo]="'body'"
              (open)="handleScroll()"
              (close)="handleScroll()"
              [clearable]="false"
              [searchable]="true"
              placeholder="Select"
              class="line-select"
              [ngClass]="{
                'input-error': !inValidData && element.to == element.from_id
              }"
            >
              <ng-option *ngFor="let item of locationList" [value]="item.id">{{
                item.name
              }}</ng-option>
            </ng-select>
          </div> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="unitPrice">
        <th mat-header-cell *matHeaderCellDef>Unit Price</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['Productcost']">{{
            element.cost ? (element.cost | currency) : "-"
          }}</span>
          <div *ngIf="!isDetailView && !disabledColumns['Productcost']">
            <span>$ </span>
            <mat-form-field
              class="custom-inputCOST"
              appearance="legacy"
              floatLabel="never"
              [ngClass]="{
                'input-error':
                  !inValidData &&
                  (element.cost
                    ? false
                    : true || element.cost <= 0 || element.cost > 100000)
              }"
            >
              <input
                min="1"
                max="100000"
                [(ngModel)]="element.cost"
                placeholder="0.00"
                type="number"
                matInput
                (change)="
                  calcTotalQty();
                  calcNetTotal();
                  onchangeCostVendor(element.cost, element);
                  sendDataToParent();
                  maxLimitForCost(element)
                "
                onkeydown="if(event.key==='e' || event.key==='+' || event.key==='-'){event.preventDefault();}"
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef>
          {{
            moduleName == "Stock" && isDetailView ? "Qty to Transfer" : "Qty"
          }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span *ngIf="isDetailView || disabledColumns['qty']">{{
            element?.quantity ? element?.quantity : "-"
          }}</span>
          <div
            class="custom-input"
            *ngIf="!isDetailView && !disabledColumns['qty']"
          >
            <mat-form-field
              appearance="legacy"
              floatLabel="never"
              [ngClass]="{
                'input-error':
                  !inValidData &&
                  (element.quantity
                    ? false
                    : true ||
                      element.quantity <= 0 ||
                      element.quantity > 100000)
              }"
            >
              <input
                placeholder="0"
                type="number"
                onkeydown="if(event.key==='.'){event.preventDefault();}"
                oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                [(ngModel)]="element.quantity"
                matInput
                min="1"
                [max]="
                  moduleName == 'ASN'
                    ? this.initialData[i].quantity
                    : moduleName == 'Stock'
                    ? element.availbleQty
                    : 100000
                "
                (change)="
                  maxLimitForQty(element, this.initialData[i]?.quantity);
                  calcTotalQty();
                  calcNetTotal();
                  sendDataToParent();
                  maxLimitForQtyGeneral(element)
                "
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="receivedQuantity">
        <th mat-header-cell *matHeaderCellDef>Received Qty</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span *ngIf="isDetailView || disabledColumns['receivedQuantity']">{{
            element.received_quantity ||
            element.received_quantity == "0"
            ? element.received_quantity
            : "-"
            }}</span>
        </td>
      </ng-container>


      <ng-container matColumnDef="receiver_no">
        <th mat-header-cell *matHeaderCellDef>Receiver #</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span *ngIf="isDetailView || disabledColumns['receiver_no']">{{
            element.receiver ? element.receiver : "-"
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Unloadqty">
        <th mat-header-cell *matHeaderCellDef>Unload Qty</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span *ngIf="isDetailView || disabledColumns['Unloadqty']">{{
            element.received_quantity || element.received_quantity == "0"
              ? element.received_quantity
              : "-"
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Asnqty">
        <th mat-header-cell *matHeaderCellDef>ASN Qty</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span *ngIf="isDetailView || disabledColumns['Asnqty']">{{
            element.quantity ? element.quantity : "-"
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Picked Location</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span
            *ngIf="
              !isEditDiscrepancy &&
              (isDetailView || disabledColumns['location'])
            "
            >{{
              element.storage_location ? element.storage_location : "-"
            }}</span
          >
          <div *ngIf="isDetailView && isEditDiscrepancy">
            <mat-form-field appearance="legacy" floatLabel="never">
              <input matInput [(ngModel)]="element.storage_location" />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Receivedqty">
        <th mat-header-cell *matHeaderCellDef>Received Qty</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span *ngIf="isDetailView || disabledColumns['Receivedqty']">{{
            element.putaway_quantity ? element.putaway_quantity : "-"
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Qty_to_pick">
        <th mat-header-cell *matHeaderCellDef>Qty to Pick</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span
            *ngIf="
              !isEditDiscrepancy &&
              (isDetailView || disabledColumns['Qty_to_pick'])
            "
            >{{ element.total_quantity ? element.total_quantity : "-" }}</span
          >
          <div *ngIf="isDetailView && isEditDiscrepancy">
            <mat-form-field appearance="legacy" floatLabel="never">
              <input
                placeholder="0"
                type="number"
                onkeydown="if(event.key==='.'){event.preventDefault();}"
                oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                matInput
                [(ngModel)]="element.total_quantity"
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="picked_quantity">
        <th mat-header-cell *matHeaderCellDef>Picked Qty</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span
            *ngIf="
              !isEditDiscrepancy &&
              (isDetailView || disabledColumns['picked_quantity'])
            "
            >{{
              element.picked_quantity || element.picked_quantity == "0"
                ? element.picked_quantity
                : "-"
            }}</span
          >
          <div *ngIf="isDetailView && isEditDiscrepancy">
            <mat-form-field appearance="legacy" floatLabel="never">
              <input
                placeholder="0"
                type="number"
                onkeydown="if(event.key==='.'){event.preventDefault();}"
                oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                matInput
                [(ngModel)]="element.picked_quantity"
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete_discrepancy">
        <th mat-header-cell *matHeaderCellDef class="text-center"></th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <div
            *ngIf="isDetailView && isEditDiscrepancy"
            class="d-flex gap-4 f-s-16 justify-content-end"
          >
            <div class="cursor-pointer">
              <img
                class="mr-3"
                id="btn-clmfilter"
                src="../../../../assets/images/Delete1.svg"
                alt=""
              />
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="fulfillment_quantity">
        <th mat-header-cell *matHeaderCellDef>Packed Qty</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span
            *ngIf="isDetailView || disabledColumns['fulfillment_quantity']"
            >{{
              element.fulfillment_quantity ||
              element.fulfillment_quantity == "0"
                ? element.fulfillment_quantity
                : "-"
            }}</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="Productqty">
        <th mat-header-cell *matHeaderCellDef> {{moduleName ==='SO' ?'Qty' : 'SKU Qty'}}<sup *ngIf="moduleName ==='SO'" class="mand-staric">*</sup></th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span *ngIf="isDetailView || disabledColumns['Productqty']">{{
            element?.quantity ? element?.quantity : "-"
          }}</span>
          <div
            class="custom-input"
            *ngIf="!isDetailView && !disabledColumns['Productqty']"
          >
            <mat-form-field
              appearance="legacy"
              floatLabel="never"
              [ngClass]="{
                'input-error':
                  !inValidData &&
                  (element?.quantity
                    ? false
                    : true ||
                      element?.quantity <= 0 ||
                      element?.quantity > 100000)
              }"
            >
              <input
                placeholder="0"
                type="number"
                onkeydown="if(event.key==='.'){event.preventDefault();}"
                oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                [(ngModel)]="element.quantity"
                matInput
                min="1"
                [max]="
                  moduleName == 'ASN' ? this.initialData[i]?.quantity : 100000
                "
                (change)="
                  maxLimitForQty(element, this.initialData[i]?.quantity);
                  calcTotalQty();
                  calcNetTotal();
                  sendDataToParent();
                  maxLimitForQtyGeneral(element)
                "
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="back_order_quantity">
        <th mat-header-cell *matHeaderCellDef>Back Order Qty</th>
        <td class="" mat-cell *matCellDef="let element; let i = index">
          <span
            *ngIf="isDetailView || disabledColumns['back_order_quantity']"
            >{{
              element.back_order_quantity ? element.back_order_quantity : "-"
            }}</span
          >
          <div
            class="custom-input"
            *ngIf="!isDetailView && !disabledColumns['back_order_quantity']"
          >
            <mat-form-field
              appearance="legacy"
              floatLabel="never"
              [ngClass]="{
                'input-error':
                  !inValidData &&
                  (element.back_order_quantity
                    ? false
                    : true ||
                      element.back_order_quantity <= 0 ||
                      element.back_order_quantity > 100000)
              }"
            >
              <input
                placeholder="0"
                type="number"
                [(ngModel)]="element.back_order_quantity"
                matInput
                min="1"
                [max]="
                  moduleName == 'ASN'
                    ? this.initialData[i].back_order_quantity
                    : 100000
                "
                (change)="
                  maxLimitForQty(
                    element,
                    this.initialData[i]?.back_order_quantity
                  );
                  calcTotalQty();
                  calcNetTotal();
                  sendDataToParent();
                  maxLimitForQtyGeneral(element)
                "
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Productcost">
        <th mat-header-cell *matHeaderCellDef> {{moduleName ==='SO' ?'Price' : 'SKU Cost'}}</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['Productcost']">{{
            element.cost ? (element.cost | currency) : "-"
          }}</span>
          <div *ngIf="!isDetailView && !disabledColumns['Productcost']">
            <span>$ </span>
            <mat-form-field
              class="custom-inputCOST"
              appearance="legacy"
              floatLabel="never"
              [ngClass]="{
                'input-error':
                  !inValidData &&
                  (element.cost
                    ? false
                    : true || element.cost <= 0 || element.cost > 100000)
              }"
            >
              <input
                min="1"
                max="100000"
                [(ngModel)]="element.cost"
                placeholder="0.00"
                type="number"
                matInput
                (change)="
                  calcTotalQty();
                  calcNetTotal();
                  onchangeCostVendor(element.cost, element);
                  sendDataToParent();
                  maxLimitForCost(element)
                "
                onkeydown="if(event.key==='e' || event.key==='+' || event.key==='-'){event.preventDefault();}"
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Proposedcost">
        <th mat-header-cell *matHeaderCellDef>Proposed Cost</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['Proposedcost']">
            {{
              element.proposed_cost ? (element.proposed_cost | currency) : "-"
            }}</span
          >
          <div *ngIf="!isDetailView && !disabledColumns['Proposedcost']">
            <span>$ </span>
            <mat-form-field
              class="custom-inputCOST"
              appearance="legacy"
              floatLabel="never"
              [ngClass]="{
                'input-error':
                  !inValidData &&
                  (element.proposed_cost
                    ? false
                    : true ||
                      element.proposed_cost <= 0 ||
                      element.proposed_cost > 100000)
              }"
            >
              <input
                min="1"
                max="100000"
                [(ngModel)]="element.proposed_cost"
                placeholder="0.00"
                type="number"
                matInput
                (change)="
                  calcTotalQty();
                  calcNetTotal();
                  onchangeCostVendor(element.cost, element);
                  sendDataToParent();
                  maxLimitForCost(element)
                "
                onkeydown="if(event.key==='e' || event.key==='+' || event.key==='-'){event.preventDefault();}"
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="UOM">
        <th mat-header-cell *matHeaderCellDef>UOM</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['UOM']">
            <!-- {{ element.base_unit ? element.base_unit : "-" }} -->
            {{
              element.uom_name
                ? element.uom_name
                  ? element.uom_name
                  : "-"
                : element.uom
                ? element.uom
                : "-"
            }}
          </span>
          <div
            class="custom-select poLinesSelectBox"
            *ngIf="!isDetailView && !disabledColumns['UOM']"
            style="margin-bottom: 1px"
          >
            <!-- <span>{{element.base_unit?element.base_unit: "-"}}</span> -->
            {{
              element.uom_name
                ? element.uom_name
                  ? element.uom_name
                  : "-"
                : element.uom
                ? element.uom
                : "-"
            }}

            <!-- <ng-select [(ngModel)]="element.uom_id" (change)="sendDataToParent()" [clearable]="false"
              [searchable]="false" placeholder="Select" class="line-select"
              [ngClass]="{'input-error': !inValidData && (element.uom_id? false : true)}">
              <ng-option *ngFor="let item of uom_list" [value]="item.id">{{ item.name }}</ng-option>
            </ng-select> -->
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="reqDeliverydate">
        <th mat-header-cell *matHeaderCellDef>Req. Delivery Date</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['reqDeliverydate']">{{
            element.requested_delivery_date
              ? (element.requested_delivery_date | date)
              : "-"
          }}</span>
          <div
            class="req-custom-table"
            style="margin-top: 1px"
            *ngIf="!isDetailView && !disabledColumns['reqDeliverydate']"
          >
            <mat-form-field class="custom-date-picker" appearance="fill">
              <input
                [(ngModel)]="element.requested_delivery_date_Display"
                matInput
                [matDatepicker]="picker"
                (change)="sendDataToParent()"
                [min]="todayDate"
                (click)="picker.open()"
                class="cursor-pointer"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="received_by_name">
        <th mat-header-cell *matHeaderCellDef>Assign To</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['received_by_name']">{{
            element.received_by_name ? element.received_by_name : "-"
          }}</span>
          <div
            class="req-custom-table"
            style="margin-top: 1px"
            *ngIf="!isDetailView && !disabledColumns['received_by_name']"
          >
            <mat-form-field class="custom-date-picker" appearance="fill">
              <input
                [(ngModel)]="element.received_by_name"
                matInput
                [matDatepicker]="picker"
                (change)="sendDataToParent()"
                [min]="todayDate"
                (click)="picker.open()"
                class="cursor-pointer"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <!-- (isDetailView && status !== 'rfq')
      (!isDetailView || status === 'rfq')-->

      <ng-container matColumnDef="vendors">
        <th mat-header-cell *matHeaderCellDef>Vendor</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['vendors']">{{
            element.vendor_name ? element.vendor_name : "-"
          }}</span>
          <div
            class="custom-select poLinesSelectBox"
            style="margin-bottom: 1px"
            *ngIf="!isDetailView && !disabledColumns['vendors']"
          >
            <ng-select
              [(ngModel)]="element.vendor_id"
              [appendTo]="'body'"
              (open)="handleScroll()"
              (close)="handleScroll()"
              (change)="
                onchangeCostVendor(element.vendor_id, element, $event);
                sendDataToParent()
              "
              [clearable]="true"
              [searchable]="false"
              placeholder="Select"
              class="line-select"
            >
              <ng-option *ngFor="let item of vendorList" [value]="item.id">{{
                item.name
              }}</ng-option>
            </ng-select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="warehouse">
        <th mat-header-cell *matHeaderCellDef> {{moduleName ==='SO' ?'Warehouse' : 'Assign Warehouse'}}</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="isDetailView || disabledColumns['warehouse']">{{
            element.warehouse_name ? element.warehouse_name : "-"
          }}</span>
          <div
            class="custom-select poLinesSelectBox"
            style="margin-bottom: 1px"
            *ngIf="!isDetailView && !disabledColumns['warehouse']"
          >
            <ng-select
              [(ngModel)]="element.warehouse_id"
              (open)="handleScroll()"
              (close)="handleScroll()"
              (change)="getProductsQuantity(element, $event)"
              [searchable]="false"
              placeholder="Select"
              class="line-select"
              [ngClass]="{
                'input-error':
                  !inValidData &&
                  moduleName == 'ASN' &&
                  (this.service.accountTypeAPI == 'wms_service'
                    ? element.warehouse_id
                      ? false
                      : true
                    : '')
              }"
            >
              <ng-option *ngFor="let item of warehouseList" [value]="item.id">{{
                item.name
              }}</ng-option>
            </ng-select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="inFulfillmentQty">
        <th class="text-center" mat-header-cell *matHeaderCellDef>{{moduleName == 'fulfillmentRequest' && isDetailView ? 'Total Qty' :'In Fulfillment Qty'}}</th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let element; let i = index"
        >
          <span *ngIf="isDetailView || disabledColumns['inFulfillmentQty']">{{
            element.fulfillment_quantity ? element.fulfillment_quantity : "-"
          }}</span>
          <div
            class="custom-input"
            *ngIf="!isDetailView && !disabledColumns['inFulfillmentQty']"
          >
            <mat-form-field
              appearance="legacy"
              floatLabel="never"
              [ngClass]="{
                'input-error':
                  !inValidData &&
                  (element.fulfillment_quantity
                    ? false
                    : true ||
                      element.fulfillment_quantity <= 0 ||
                      element.fulfillment_quantity > 100000)
              }"
            >
              <input
                placeholder="0"
                type="number"
                [(ngModel)]="element.fulfillment_quantity"
                matInput
                min="1"
                [max]="
                  moduleName == 'ASN'
                    ? this.initialData[i].fulfillment_quantity
                    : 100000
                "
                (change)="
                  maxLimitForQty(
                    element,
                    this.initialData[i]?.fulfillment_quantity
                  );
                  calcTotalQty();
                  calcNetTotal();
                  sendDataToParent();
                  maxLimitForQtyGeneral(element)
                "
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="availableQty">
        <th mat-header-cell *matHeaderCellDef>Available Qty</th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let element; let i = index"
        >
          <span
            *ngIf="isDetailView || disabledColumns['availableQty']"
            [ngStyle]="
              element.inventory_available_quantity === 0 ||
              element.inventory_available_quantity < 0
                ? { color: '#FF1B6D' }
                : element.inventory_available_quantity > 0
                ? { color: '#39C673' }
                : { color: '#000000' }
            "

           [matTooltip]="shouldShowTooltip(
            element.inventory_available_quantity === 0 ||
            element.inventory_available_quantity > 0 ||
            element.inventory_available_quantity < 0
              ? element.inventory_available_quantity
              : '-'
          ) ? getTooltipMessage(element.inventory_available_quantity) : ''"
            matTooltipPosition="above"
          >
          {{
              element.inventory_available_quantity === 0 ||
              element.inventory_available_quantity > 0 ||
              element.inventory_available_quantity < 0
                ? element.inventory_available_quantity
                : "-"
            }}
          </span>

          <div
            class="custom-input"
            *ngIf="!isDetailView && !disabledColumns['availableQty']"
          >
            <mat-form-field
              appearance="legacy"
              floatLabel="never"
              [ngClass]="{
                'input-error':
                  !inValidData &&
                  (element.inventory_available_quantity
                    ? false
                    : true ||
                      element.inventory_available_quantity <= 0 ||
                      element.inventory_available_quantity > 100000)
              }"
            >
              <input
                placeholder="0"
                type="number"
                [(ngModel)]="element.inventory_available_quantity"
                matInput
                min="1"
                [max]="
                  moduleName == 'ASN'
                    ? this.initialData[i].inventory_available_quantity
                    : 100000
                "
                (change)="
                  maxLimitForQty(
                    element,
                    this.initialData[i]?.inventory_available_quantity
                  );
                  calcTotalQty();
                  calcNetTotal();
                  sendDataToParent();
                  maxLimitForQtyGeneral(element)
                "
              />
            </mat-form-field>
            ERTY
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="note_attachmentPOPUP">
        <th mat-header-cell *matHeaderCellDef class="text-center"></th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <div class="d-flex gap-4 f-s-16 justify-content-end">
            <div
              *ngIf="(element.notes && this.isDetailView) || !this.isDetailView"
              class="cursor-pointer"
              (click)="opennotes(element)"
            >
              <i class="icon-notes"></i>
            </div>
            <!-- <div *ngIf="
                (element.attachments?.length > 0 && this.isDetailView) || !this.isDetailView
              " class="cursor-pointer" (click)="openattachment(element)">
              <i class="icon-attachment"></i>
            </div> -->
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="uploadLabel">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          Upload Label
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <div
            class="d-flex align-items-center menu-btn"
            style="justify-content: space-around"
          >
            <img
              (click)="openuploadlabel(element)"
              src="../../../../assets/images/upload-label.png"
              alt=""
              class="pr-10 cursor-pointer"
            />
            <!-- <mat-icon class='material-icons-outlined' (click)="openuploadlabel(element)"
           >file_upload</mat-icon> -->
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef class="text-center"></th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          class="text-center"
        >
        <mat-icon (click)="removePORow(i, element)" class="cursor-pointer" *ngIf="moduleName === 'SO'">delete</mat-icon>
          <button
          *ngIf="moduleName != 'SO'"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
        <mat-icon class="cursor-pointer">more_vert</mat-icon>


          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="removePORow(i, element)">
              <span>Delete</span>
            </button>
            <!-- <button mat-menu-item>
              <span>Reject</span>
            </button>
            <button mat-menu-item>
              <span>Add Notes</span>
            </button>
            <button mat-menu-item>
              <span>Upload</span>
            </button> -->
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div
    *ngIf="
      moduleName != 'Stock' &&
      moduleName != 'cycleCount' &&
      (moduleName == 'fulfillmentRequest' ||
        moduleName == 'pick' ||
        !isDetailView)
    "
    class="d-flex justify-content-end align-items-center"
  >
    <p class="f-s-14 t-shirt font-weight-bold">
      Total Qty:
      <span class="mr-4">{{
        disabledColumns["Qty_to_pick"] ? totalPickQtyDisplay : totalQtyDisplay
      }}</span>
    </p>
    <p
      *ngIf="
        (!isDetailView ||
          !(
            disabledColumns['Qty_to_pick'] ||
            disabledColumns['Unloadqty'] ||
            disabledColumns['fulfillment_quantity']
          )) &&
        moduleName !== 'kitOrders'
      "
      class="f-s-14 t-shirt font-weight-bold"
    >
      Net Total: $ <span class="mr-4">{{ NetTotalDisplay }}</span>
    </p>
  </div>
</div>
