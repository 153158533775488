import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScanLogsService } from './scan-logs.service';
import { ConfigService } from 'src/app/services/config.service';
import { AlertService } from '../alert/alert.service';
import { CommonService } from 'src/app/services/comman.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export interface DialogData {
  scanLogs: any;
  task_id: number;
  detailmode: boolean;
}
@Component({
  selector: 'app-scan-logs',
  templateUrl: './scan-logs.component.html',
  styleUrls: ['./scan-logs.component.scss']
})
export class ScanLogsComponent implements OnInit {
  scanLogs: any;
  scanLogsTableData: any;
  firstRecord: any;
  dateArray: string[] = [];
  selectedOption: string = 'Newest to oldest';
  scan_detailGridData: any = [
    {
      "user_name": "Maxwell",
      "dateTime": "24/04/2024, 3:00 PM",
      "action": "Scanned",
      "value": "ASN AN-98980",
      "status": "Valid",
      "reason": ""
    },
    {
      "user_name": "Maxwell",
      dateTime: "24/04/2024, 3:00 PM",
      action: "Entered",
      value: "SKU IP-98981",
      status: "Invalid",
      reason: "Error (Invalid SKU)"
    },
    {
      "user_name": "Maxwell",
      dateTime: "24/04/2024, 3:00 PM",
      action: "Entered",
      value: "SKU IP-98980",
      status: "Valid",
      reason: ""
    },
    {
      "user_name": "Maxwell",
      dateTime: "24/04/2024, 3:00 PM",
      action: "Entered",
      value: "Qty 1",
      status: "Valid",
      reason: ""
    },
    {
      "user_name": "Maxwell",
      dateTime: "24/04/2024, 3:00 PM",
      action: "Scanned",
      value: "Location 51-D18-1-1",
      status: "Valid",
      reason: ""
    }
  ];
  displayedColumnsforPOLines: string[] = [
    'user_name',
    'date_time',
    'action',
    'value',
    'status',
    'reason'
  ];

  constructor(public dialogRef: MatDialogRef<ScanLogsComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public service: ConfigService, public alert: AlertService, private commanService: CommonService, private NgxLoader: NgxUiLoaderService) {}

  doaction() {
    this.dialogRef.close(this.scanLogs);
  }

  ngOnInit(): void {
    this.scanLogs =  this.data.scanLogs;
    this.scanLogsTableData = this.data.scanLogs.scan_logs;
    this.firstRecord = this.data.scanLogs.scan_logs[0];
  }

  changeOrder(event: Event) {
    const selectedValue = this.selectedOption === 'Newest to oldest' ? 'desc' : 'asc';
    
    // Call the service method to fetch sorted data
    this.getScanlogs(this.data.task_id, selectedValue)
  }

  async getScanlogs(id: number, sort: string) {
    this.scanLogs = [];
    let scanlogApi = `/api/get-scan-logs`;
    this.NgxLoader.start();
    const response: any = await this.service.getApi(
      scanlogApi,
      {
        "task_id": id,
        "sort_by": sort
      },
      {},
      'wms_service'
     );
     if (response.status && response?.data) {
      this.scanLogs = response.data;
      this.NgxLoader.stop();
    } else {
      this.alert.show('Scan Log Not Found.', 'error');
      this.NgxLoader.stop();
      return;
    }
  }

  formatTaskId(task_id: number | undefined): string {
    return task_id ? 'TI-' + task_id.toString().padStart(5, '0') : 'TI-000010';
  }

  formatDate(date: string | Date): string {
    return new Date(date).toLocaleString();
  }

  async exportLogs() {
    try {
      this.NgxLoader.start();
    
      if (this.scanLogs) {
        this.commanService.downloadJSONtoCSVFile(this.scanLogsTableData, `${this.firstRecord.event} Scan Logs`);
      } else {
        this.alert.show('No data record found.', 'error');
      }
    } catch (error) {
      this.alert.show('No data record found.', 'error');
    } finally {
      this.NgxLoader.stop();
    }
  }

}
