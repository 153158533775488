export const environment = {
  production: false,
  baseUrl: 'https://api.develop.wizefulfill.com/',
  sellerCloudUrl: 'https://shtiks.api.sellercloud.com/rest/api/',
  authServiceUrl: 'https://api.develop.wizefulfill.com/auth_service',
  clientServiceUrl: 'https://api.develop.wizefulfill.com/client_service',
  spServiceUrl: 'https://api.develop.wizefulfill.com/wms_service',

  // authServiceUrl: 'http://localhost:8000',
  // clientServiceUrl: 'http://localhost:8001',
  // spServiceUrl: 'http://localhost:8002',
};
