import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { Title } from '@angular/platform-browser';
import { ConfigService } from './services/config.service';
import { Subscription } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { CustomSnackbarService } from 'src/app/pages/shared/custom-snackbar/custom-snackbar.service';
import { AlertService } from './pages/shared/alert/alert.service';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  mode: ProgressSpinnerMode = 'determinate';
  value = 50;
  title = 'WizeFulfill';
  time: any;
  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    public service: ConfigService,
    public loader:LoaderService,
    public snackbarService:CustomSnackbarService,
    public alert: AlertService
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });

  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('rememberMe');
    this.router.navigate(['login']);
  }

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('document:wheel')
  resetTimer() {
    clearTimeout(this.time);
    this.time = setTimeout(() => {
      let remember = localStorage.getItem('rememberMe');
      if(remember === "false" || remember === undefined){
        this.alert.show('The session is timed out for security reasons due to being idle.', 'error');
        this.logout();
      }
      // Idle for 15 minutes which is 900000
    }, 900000);
  }



}
