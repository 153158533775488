import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private snackbarSubject = new Subject<{ show: boolean, audits: any, title: any, showLogs?: boolean }>();
  private orderChangeSubject = new Subject<{ dataArray: any }>();
  public historyState = this.snackbarSubject.asObservable();
  public historyOrderChange = this.orderChangeSubject.asObservable();
  task_id: number | undefined;
  historyData: any;

  constructor(public service: ConfigService, public alert: AlertService) { }

  orderChanged(dataArray: any) {
    this.orderChangeSubject.next({
      dataArray: dataArray,
    });
  }

  show(moduleTitle: any, auditData: any, taskId?: number, showScanLogs?: boolean) {
    this.task_id = taskId;
    this.snackbarSubject.next({
      show: true,
      audits: auditData,
      title: moduleTitle,
      showLogs: showScanLogs || false,
    });
  }

  close() {
    this.snackbarSubject.next({
      show: false,
      audits: [],
      title: '',
    });
  }

  async getSetupHistory(module_id: any, module_name: string, label: string, sortOrder: string) {
    let historyApi = `/api/activity-log?module_id=${module_id}&module_name=${module_name}&sort=${sortOrder}`;
    const response: any = await this.service.getApi(
      historyApi,
      {},
      {},
      'wms_service'
     );
     if (response.status && response?.data?.historyData?.length) {
      this.historyData = response.data?.historyData;
      this.show(`${label} History`, this.historyData)
    } else {
      this.alert.show('History Not Found.', 'error');
      return;
    }
  }
}
