export class constants {
  public static clientServiceAPI = 'client_service';
  public static serviceProviderAPI = 'wms_service';
  public static adminAPI = 'auth_service';

  public static AccountManagementAPI = '/api/users';

  public static purcahseOrderAPI = '/api/purchaseOrders';
  public static purcahseRequestAPI = '/api/purchaseRequests';
  public static asnAPI = '/api/asn';

  public static productsAPI = '/api/products/lookup';

  public static soAPI = '/api/salesOrders';

  public static assemblyAPI = '/api/kits';
  public static kitToOrdersAPI = '/api/kit_orders';
  public static salesRequestAPI = '/api/salesRequests';

  public static cycleCountAPI = '/api/cycle-count';
  public static cycleCountPopupAPI = '/api/cycle-count/inventory-data';
  public static fulfillmentRequest = '/api/fulfillment';
  public static inventoryOverview = '/api/inventories';
  public static stockTransfer = '/api/inventories/stock-transfer';
  public static inventoryAdjustment = '/api/inventory-adjustments-reason-code';

  public static productAPI = '/api/products';
  public static receiveAPI = '/api/receiving';
  public static unloadReceiveAPI = '/api/unload-receiving';
  public static unloadAPI = '/api/unload';
  public static customerAPI = '/api/customers';
  public static clientAPI = '/api/client';
  public static usersAPI = '/api/users';
  public static warehouseAPI = '/api/warehouse';
  public static areaAPI = '/api/areas';
  public static parentAreaAPI = '/api/parent-areas';
  public static locationAPI = '/api/location';
  public static importLocationAPI = '/api/import-locations';
  public static vendorsAPI = '/api/vendors';
  public static pickAPI = '/api/pick';
  public static packAPI = '/api/pack';
  public static boxAPI = '/api/box';
  public static userRole = '/api/user_role';
  public static carrier_configurationAPI = '/api/marketplace';
  public static tasktypeAPI = '/api/task_type';
  public static automationRulesAPI = '/api/automation_rules';
  public static taskAPI = '/api/tasks';
  public static workflowAPI = '/api/workflows';
  public static printerSettingsAPI = '/api/printer';


}
