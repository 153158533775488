import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Attribute,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ConfigService } from '../../../services/config.service';
import { AlertService } from '../alert/alert.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridColumnfilterComponent } from '../grid-columnfilter/grid-columnfilter.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DeletePopupComponent } from '../delete-popup/delete-popup.component';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

export interface paginationData {
  current_page: any; //current_PageNo
  last_page: any; // page_Count
  per_page: any; // page_Size
  total: any;
  to: any;
  from: any;
}

@Component({
  selector: 'shtiks-list-updated',
  templateUrl: './list-shtiks.component.html',
  styleUrls: ['./list-shtiks.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ListShtiksComponent implements OnInit {
  private eventsSubscription: Subscription;
  @Input() ClearSelection: Observable<void>;
  // @Input() isActionColVisible: boolean = true;
  @Input() isActionColVisible: boolean = true; // decorate the property with @Input()
  noOfEntries = ['500', '350', '250', '100', '50', '15'];
  selectedNoOfEntries: string = '15';

  expanded: boolean = false; //for animation
  sort!: MatSort;
  expandedElement: any;
  gridDataSourceShtiks = new MatTableDataSource([]);
  paginationDataSourceShtiks = <paginationData>{};
  columnsToDisplayShtiks: any = [];
  columnsToDisplayFilter: any = [];
  initColumns: any = [];
  innerGridColums: any = [];
  innerGridColumsName: any = [];
  customStleClasses: string;

  innerGridData: any[] = [];
  selection: any;
  isGridTab: any;

  isSelectableRow;
  hasPivot;
  hasInvestigate;
  hasPriority;
  // hasFirstInvestigate;

  uom_list: any;
  isallowedAction: any;
  isallowedSort: any;
  // isVisibleAction: any;
  showWSbuttons: any;
  showAreabuttons: any;
  toShow: any = true;

  ModuleName: any;
  hasMoreHeader: boolean = false;
  expandable_grid: boolean = false;

  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  isfirstInvestigationDone: boolean;
  completed: boolean;
  asc: boolean = true;

  @Input('hasMoreHeader') set _hasMoreHeader(value: boolean) {
    this.hasMoreHeader = value;
  }

  @Input() customCssClass: string;

  @Input('ModuleName') set moduleName(value: any) {
    this.ModuleName = value;
  }

  @Input('EnableAction') set ActionPermission(value: boolean) {
    this.isallowedAction = value;
  }
  @Input('EnableSort') set SortPermission(value: boolean) {
    this.isallowedSort = value;
    console.log(this.isallowedSort,'this.isallowedSort');
  }
  @Input('firstInvestigation') set firstInvestigation(value: boolean) {
    this.isfirstInvestigationDone = value;
    console.log(this.isfirstInvestigationDone, 'isfirstInvestigationDone');
  }
  @Input('isCompleted') set isCompleted(value: boolean) {
    this.completed = value;
    console.log(this.completed, 'completed');
  }
  // @Input('VisibleAction') set ActionVisible(value: boolean) {
  //   this.isVisibleAction = value;
  // };
  @Input('GridData') set gridData(value: any) {
    //  console.log('gridDataSource', value)

    this.gridDataSourceShtiks.data = value;

    this.selection = new SelectionModel(true, []);
  }
  @Input('PaginationData') set PaginationData(value: any) {
    this.paginationDataSourceShtiks = value;
  }

  @Input('isGridTab') set isSetGridTab(value: any) {
    this.isGridTab = value;
  }

  @Input('ExpandableGrid') set expandGrid(value: boolean) {
    this.expandable_grid = value;
  }

  @Input('CustomStyleClass') set _CustomStyleClass(value: any) {
    this.customStleClasses = value;
  }

  @Input('MasterColumns') set _columnsToDisplay(value: Array<any>) {
    this.columnsToDisplayShtiks = [];

    if (value && value.length > 0) {
      let expendIconIndex = value.findIndex((x) => x.field == 'expandIcon');
      if (expendIconIndex !== -1) {
        value.splice(expendIconIndex, 1);
        console.error(
          "Grid Implimentation Depricated::\nThis method of defining '{... field:'expandIcon'....}' column  in '[MasterColumns]' object array is depricated,\n Kindly Remove this object from array.\nGrid Control will automatically display expandable row action button id it will find detail view Columns defination."
        );
      }
      let pivotIndex = value.findIndex((x) => x.field == 'pivot');
      if (pivotIndex !== -1) {
        value.splice(pivotIndex, 1);
        console.error(
          "Grid Implimentation Depricated::\nThis method of defining '{... field:'pivot'....}' column  in '[MasterColumns]' object array is depricated,\n Kindly Remove this object from array because its a default functionality of Grid control."
        );
      }
      let selectIndex = value.findIndex((x) => x.field == 'select');
      if (selectIndex !== -1) {
        value.splice(selectIndex, 1);
        console.error(
          "Grid Implimentation Depricated::\nThis method of defining '{... field:'select'....}' column  in '[MasterColumns]' object array is depricated,\n Kindly Provide attribute property in grid selector <v3-grid 'SelectableRows='true'> for adding Row Selection column."
        );
      }

      let isUOMSelect = value.findIndex(
        (x) => x.field == 'uom' && x.isVisible && x?.type == 'dropdown'
      );
      if (isUOMSelect !== -1) {
        this.getuom();
      }

      this.columnsToDisplayShtiks = value;
      this.columnsToDisplayFilter = value.map((x) => ({
        ...x,
        isChanged: false,
      }));

      this.updateColumnsData();
    } else {
      this.columnsToDisplayShtiks = [];
    }
  }

  @Input('InnerGridColumns') set _innerColumnsToDisplay(value: Array<any>) {
    if (value && value.length > 0) {
      this.innerGridColums = value;
      this.innerGridColumsName = value.map((x: any) => x.field);
    } else {
      this.innerGridColums = [];
    }
  }

  @Output('AllSelected') allSelected = new EventEmitter<any>();
  @Output('RowSelected') rowSelected = new EventEmitter<any>();

  @Output('pagechange') pageChange = new EventEmitter<any>();
  @Output('actionClick') actionClick = new EventEmitter<any>();
  @Output('trackingClicked') trackingClicked = new EventEmitter<any>();
  @Output('ForQtyFinalCount') ForQtyFinalCount = new EventEmitter<any>();

  @Output('pagesize') pageSize = new EventEmitter<any>();

  @Output() innerGridClickEmitter = new EventEmitter<any>();
  @Output() sortDirectionChanged = new EventEmitter<[string, any]>();

  emitSortDirection(direction: string, column: any) {
    console.log(direction,'direction')
    this.sortDirectionChanged.emit([direction, column]);
    this.toggleAscDesc();
  }
  toggleAscDesc() {
    this.asc = !this.asc;
  }
  isDivDisabled(): boolean {
    return !this.gridDataSourceShtiks.data || this.gridDataSourceShtiks.data.length < 1
  }
  constructor(
    public dialogRefWarning: MatDialogRef<ConfirmationComponent>,
    private _router: Router,
    @Attribute('SelectableRows') _isSelectableRow: boolean,
    @Attribute('pivotedItem') _hasPivot: any,
    @Attribute('investigateItem') _hasInvestigate: any,
    @Attribute('priorityTask') _hasPriority: any,

    // @Attribute('firstInvestigation') _hasFirstInvestigate: any,

    @Attribute('allowAction') _hasActionPermission: any,
    @Attribute('allowSort') _hasSortPermission: any,
    // @Attribute('isActionColVisible') _hasActionVisible: any,
    @Attribute('wsButtons') _hasWS: any,
    @Attribute('areaButtons') _hasArea: any,
    public service: ConfigService,
    public dialogRefDeletePopup: MatDialogRef<DeletePopupComponent>,
    public alert: AlertService,
    public dialog: MatDialog,
    private NgxLoader: NgxUiLoaderService,
    public dialogRef: MatDialogRef<GridColumnfilterComponent>
  ) {
    this.isSelectableRow = _isSelectableRow;
    this.hasPivot = _hasPivot;
    this.hasInvestigate = _hasInvestigate;
    this.hasPriority = _hasPriority;

    // this.hasFirstInvestigate = _hasFirstInvestigate;

    this.isallowedAction = _hasActionPermission;

    // this.isVisibleAction = _hasActionVisible;
    this.showWSbuttons = _hasWS;
    this.showAreabuttons = _hasArea;
  }

  activeURL: string = '';
  ngOnInit() {
    console.log("hasPriority",this.hasPriority);

    this.activeURL = this._router.url.replace('/list', '/detail');
    // console.log(this._router.url,'this._router.url');

    if (this.ClearSelection) {
      this.eventsSubscription = this.ClearSelection.subscribe(() =>
        this.clearSelection()
      );
    }
  }

  deleteWarning(element: any) {
    this.dialogRefDeletePopup = this.dialog.open(DeletePopupComponent, {
      width: '320px',
      height: '210px',
    });
    this.dialogRefDeletePopup.beforeClosed().subscribe((data) => {
      if (data == '1') {
        // this.list()
        this.acionClick('delete', element);
      }
    });
  }

  clearSelection() {
    this.selection = new SelectionModel(true, []);
  }

  checkforInnerGrid(ele: any) {
    this.innerGridData = ele?.product?.request_lines;
    //console.log('innergrid', this.innerGridData);
    return this.innerGridData?.length ? true : false;
  }

  updateColumnsData() {
    this.initColumns = [];

    if (
      this.isSelectableRow &&
      (this.isSelectableRow === true || this.isSelectableRow === 'true')
    ) {
      this.initColumns.push('select');
    }

    this.columnsToDisplayShtiks.forEach((col: any) => {
      if (col.isVisible) {
        this.initColumns.push(col.field);
      }
    });
    if (
      this.hasPriority === true||
      this.hasInvestigate === 'true'
    ) {
      this.initColumns.push('priority');
    }

    if (this.hasPivot === true || this.hasPivot === 'true') {
      this.initColumns.push('pivot');
    }

    if (
      this.hasInvestigate === true ||
      this.hasInvestigate === 'true' ||
      this.isfirstInvestigationDone === true
    ) {
      this.initColumns.push('investigate');
    }
    this.initColumns.push('expand');
  }

  /**---------------------CheckBox Functionality --START-- --------------------**/

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.gridDataSourceShtiks.data.length;
    return numSelected === numRows;
  }

  masterToggle(isAllSelected: boolean) {
    if (isAllSelected) {
      this.gridDataSourceShtiks.data.forEach((row) =>
        this.selection.select(row)
      );
      this.allSelected.emit(this.gridDataSourceShtiks.data);
      ////  console.log(this.gridDataSourceShtiks.data,"list shitks rows")
    } else {
      this.selection.clear(); // clear all selections
      this.allSelected.emit([]); // emit all sections as `false`
      this.rowSelected.emit([]); // emit empty array to un-select all products.
    }

    // if (this.selection.selected.length > 0) {
    //   this.selection.clear();
    //   this.allSelected.emit(false);
    // } else if (this.selection.selected.length == 0) {
    //   this.gridDataSourceShtiks.data.forEach((row) => this.selection.select(row));
    //   this.allSelected.emit(true);
    // }
    // this.matCheckAllElement.checked = this.isAllSelected();
  }

  rowToogle(event: any, row: any) {
    // if (this.ModuleName == "Stock") {
    //   this.selection.clear();
    // }
    this.selection.toggle(row);
    ////  console.log(row, event, this.selection, "single row data")
    this.rowSelected.emit(this.selection.selected);

    //  this.matCheckAllElement.checked = this.isAllSelected();
  }
  /**---------------------CheckBox Functionality --END-- --------------------**/

  acionClick(event: any, element: any) {
    this.actionClick.emit({ name: event, data: element });
  }

  trackingClick(element: any) {
    this.trackingClicked.emit({ data: element });
  }

  acionInvestigate(event: any, element: any) {
    this.actionClick.emit({ name: event, data: element });
  }
  acionPriority(event: any, element: any) {
    this.actionClick.emit({ name: event, data: element });
  }

  QtyFinalCount(element: any) {
    this.ForQtyFinalCount.emit({ data: element });
  }

  async pagechange(event: any) {
    this.pageChange.emit(event);
  }

  async getuom() {
    const response: any = await this.service.getApi('/api/uoms/lookup', {});
    if (response.success && response.data.length > 0) {
      this.uom_list = response.data;
    } else {
      if (response.data.length <= 0) {
        //  this.alert.show('No data record found for selected criteria', 'error');
      } else {
        this.alert.show(response.error.message, 'error');
      }
    }
  }

  get_uom_list(product: any) {
    ////  console.log('uom list getting......', this.uom_list, product, product.base_unit)
    switch (product.base_unit) {
      case 'EA': {
        // product.uom_id = 1;
        // return this.uom_list.filter((data:any)=>data.id>=2)
        return this.uom_list;
        // break;
      }
      case 'Case': {
        // product.uom_id = 2;

        return this.uom_list.filter((data: any) => data.id >= 2);
      }
      case 'Carton': {
        // product.uom_id = 3;

        return this.uom_list.filter((data: any) => data.id >= 3);
        // return this.uom_list
      }
      case 'Pallet': {
        // product.uom_id = 4;

        return this.uom_list.filter((data: any) => data.id >= 4);
        // return this.uom_list
      }
      default: {
        return this.uom_list;
      }
    }
    // return this.uom_list
  }

  /* Entries dropdow functionality */
  onSelectEntry(pageSize: any) {
    this.pageSize.emit(this.selectedNoOfEntries);
  }

  maxLimitForQtyGeneral(current: any) {
    let max = 100000;
    if (current.quantity > max) {
      this.alert.show('Max Limit for Quantity is 100000', 'error');
    }
  }

  async getInventoryCount(element: any, product_id: any, type: any) {
    this.NgxLoader.start();
    if (type == 'incoming') {
      const response: any = await this.service.getApi(
        '/api/inventories/incoming-inventory-count/' + product_id,
        ''
      );
      if (response.status && response.data) {
        element.isIncoming = true;
        element.incoming_qty =
          response.data.incoming != null ? response.data.incoming : 0;
      } else {
        element.isIncoming = true;
        element.incoming_qty = 0;
      }
    } else if (type == 'intransit') {
      const response: any = await this.service.getApi(
        '/api/inventories/intransit-inventory-count/' + product_id,
        ''
      );
      if (response.status && response.data) {
        element.isIntransit = true;
        element.intransit_qty =
          response.data.intransit != null ? response.data.intransit : 0;
      } else {
        element.isIntransit = true;
        element.intransit_qty = 0;
      }
    } else if (type == 'reserve') {
      const response: any = await this.service.getApi(
        '/api/inventories/reserve-inventory-count/' + product_id,
        ''
      );
      if (response.status && response.data) {
        element.isReserve = true;
        element.reserve_qty =
          response.data.reserve != null ? response.data.reserve : 0;
      } else {
        element.isReserve = true;
        element.reserve_qty = 0;
      }
    }

    this.NgxLoader.stop();
  }

  gridColumnFilter() {

    const element = document.getElementById('btn-clmfilter');
    const rect = element
      ? element.getBoundingClientRect()
      : { top: 0, left: 0 };

    //console.log(this.columnsToDisplayFilter);

    this.dialogRef = this.dialog.open(GridColumnfilterComponent, {
      data: {
        data: this.columnsToDisplayFilter,
      },
      backdropClass: 'backdrop',
      disableClose: false,
      width: '240px',
      minHeight: '430px',
      position: { top: rect.top + 28 + 'px', left: rect.left - 220 + 'px' },
    });

    this.dialogRef.beforeClosed().subscribe((data: any) => {
      if (data !== undefined && data.length > 0) {
        this.columnsToDisplayFilter.map((col: any) => {
          if (col.isChanged) {
            col.isChanged = false;
          }
        });
        this.columnsToDisplayShtiks = data;
        this.updateColumnsData();
      } else {
        this.columnsToDisplayFilter.map((col: any) => {
          if (col.isChanged) {
            col.isVisible = !col.isVisible;
            col.isChanged = false;
          }
        });
      }
    });
  }

  innerGridClick(row: any) {
    this.innerGridClickEmitter.emit(row);
  }

  openPopup() {
    this.dialogRefWarning = this.dialog.open(ConfirmationComponent, {
      data: {
        width: '120px',
        height: '100px',
        message: `I can't find it`,
        heading: true,
        heading_text: `Trouble finding SKU`,
      },
    });
  }

  reloadDataTable() {
    this.table.renderRows();
  }

    // Function to check the type of field
    checkFieldType(field: any): string {
      if (Array.isArray(field)) {
        return 'array';
      } else if (typeof field === 'string') {
        return 'string';
      } else {
        return 'unknown';
      }
    }
}
